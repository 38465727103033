import React from 'react'
import classes from './BasicHeader.module.scss'


class BasicHeader extends React.Component {
    render() {
        return (
            <div className={classes.BasicHeader}>
                {/*<HeaderNav/>*/}
                <h1>{this.props.headTitle}</h1>
            </div>
        )
    }
}

export default BasicHeader