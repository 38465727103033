import React, {useState} from 'react'
import classes from './EBRENav.module.scss'

const EBRENav = props => {
    const [activeHeader, setActiveHeader] = useState('Education')

    return (
        <div className={classes.EBRENav}>
            <div className={classes.ContentContainer}>
                <div className={activeHeader === 'Education' ? classes.active : null}>
                    <h1 onClick={() => {setActiveHeader('Education')}} id={'educationHeader'}>
                        Education
                    </h1>
                    {
                        activeHeader === 'Education' ?
                            educationContent
                        : ''
                    }
                </div>
                <div
                      className={activeHeader === 'Business' ? classes.active : null}
                >
                    <h1 onClick={() => {setActiveHeader('Business')}} id={'businessHeader'}>
                        Business
                    </h1>
                    {
                        activeHeader === 'Business' ?
                            businessContent

                            : ''
                    }
                </div>
                <div className={activeHeader === 'RealEstate' ? classes.active : null}>
                    <h1 onClick={() => {setActiveHeader('RealEstate')}} id={'realEstateHeader'}>
                        Real Estate
                    </h1>

                    {
                        activeHeader === 'RealEstate' ?
                            realEstateContent

                        : ''
                    }
                </div>
             </div>

            <div className={classes.ContentContainerDesktop}>
                <div className={classes.DesktopHeaders}>
                    <h1
                        className={activeHeader === 'Education' ? classes.active : null}
                        onClick={() => {setActiveHeader('Education')}}
                        id={'educationHeader'}
                    >
                        Education
                    </h1>
                    <h1
                        className={activeHeader === 'Business' ? classes.active : null}
                        onClick={() => {setActiveHeader('Business')}}
                        id={'businessHeader'}
                    >
                        Business
                    </h1>
                    <h1
                        className={activeHeader === 'RealEstate' ? classes.active : null}
                        onClick={() => {setActiveHeader('RealEstate')}}
                        id={'realEstateHeader'}
                    >
                        Real Estate
                    </h1>
                </div>
                <div className={classes.DesktopContent}>
                    { activeHeader === 'Education' ? educationContent : '' }
                    { activeHeader === 'Business' ?businessContent: '' }
                    { activeHeader === 'RealEstate' ? realEstateContent : '' }
                </div>
            </div>
        </div>
    )
}

const educationContent = (<p>
    Why have over 75 000 students picked Ukraine as their academic destination?
    The answer is obvious: the education quality and location of Ukraine have made
    it a perfect choice for those who appreciate European standards of education at affordable costs.
    The Ukrainian education system has decades of being considered one of the best and most efficient
    education systems in the world in store, and will benefit most to those who are interested in such
    areas of academic development as Medicine and pharmacology, Avionics and rocket technology,
    Applied physics and nanotechnology, IT services as well as Biology and Genetics. Choosing education
    in Ukraine means not only having a vast variety of choice of subjects to study, but also getting a
    diploma that’ll be accredited and acknowledged all around the world. Flexible programs for foreign
    applicants and affordability of life overall, taken the high quality of it, make Ukraine an extremely
    attractive academic destination. On our side, we ensure the step-by-step university application control
    and lead you every minute of the way to your brilliant scholar future!
</p>)

const businessContent = (<p>
    Recently Ukraine has become an attractive destination for starting a business project.
    Due to the beneficial geographic location, rich natural resources, affordable but highly
    skilled workforce, being the connection chain between East and West, Ukraine is an ultimate
    choice to implement your business ideas. Among the most popular spheres one can find:
    <ul>
        <li>Agriculture with plenty of development perspectives;</li>
        <li>IT sector being the fastest-growing area of the local economy with very favorable tax rates;</li>
        <li>Import of huge variety of products such as textile, ceramics, food, clothes etc.;</li>
        <li>Restaurants and bars with foreign authentic cuisine that are in high demand among Ukrainian youth.</li>
        We’ll be glad to put your business ideas into practice and assist you along the process.
    </ul>
</p>)

const realEstateContent = (<p>
    Being a fast-growing developing country, Ukraine still has very
    moderate prices in real estate sector which is considered to be a
    highly attractive investment, regardless of whether you do it for
    investment purposes, rental income or your personal use. There is a lot
    of property types that can satisfy every need, from the classy apartments
    in the city center up to luxury townhouses in suburban areas. We ensure
    that you have a hassle-free process of selecting your ideal property and
    support you on every step up to closing a deal.
</p>)

export default EBRENav

