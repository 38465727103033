export const OguzFeedback = "I have stayed in Ukraine, Kharkiv, in business for a while, " +
    "and with the help of Ukraine4Everyone ladies I have been able to not only arrange my work matters, " +
    "but also to organize a trip to Western Ukraine which was a great success. The girls have taken care of everything, " +
    "starting with the route and to having booked the stay for me. They have also helped me to take care of my dog, Rio, to set " +
    "her to the pet hotel and to help communicate with the vets regarding her dog documents and vaccination. Thank you for making my " +
    "stay in Ukraine easier and more pleasant!\n"

export const YolandaFeedback = "I have met Christina during my work in Shanghai, China, " +
    "and after I have finished my contact there, I have come to a residence in Spain. " +
    "Visiting Ukraine was one of my goals since the days of my youth, and once Christina has offered to plan " +
    "a short trip for me, I have agreed without thinking! I have come to see Kyiv first and the lion share of " +
    "my stay in Ukraine was in the Carpathians, which I enjoyed greatly! My trip was full of impressions and national colour, " +
    "and also seamless due to Christina’s help and organization. If you are looking for someone to take care of your Ukrainian " +
    "vacation and to make it unforgettable – then look no further!\n"

export const EugeneFeedback = "Amazing service and organization! Last summer I decided to travel to Ukraine, " +
    "but I didn’t know even from where to begin and what places to choose. Thank you girls for organizing such an amazing vacation for me! " +
    "I really enjoyed my stay in Kharkiv, Kyiv, Odesa and Zaporizhzhia, every detail such as accommodation, tickets, " +
    "excursions was planned just perfect. For sure I’ll be back and I definitely recommend you guys to everyone " +
    "who needs any kind of help in Ukraine.\n"