import React from 'react'
import classes from './Reviews.module.scss'
import cx from 'classnames'

const ReviewWithVerticalVideoContainer = ({userName, userCountry, videoSrc=''}) => {
    return (
        <div className={cx(classes.ReviewContainer, classes.ReviewWithVerticalVideoContainer)}>
            <div className={classes.verticalVideoContainer}>
                <video controls>
                    <source src={videoSrc} type="video/mp4" />
                </video>
            </div>
            <div className={classes.withVerticalVideoInfoContainer}>
                <span className={classes.UserName}>{userName}, </span>
                <span className={classes.UserCountry}>{userCountry}</span>
            </div>
        </div>
    )
}

export default ReviewWithVerticalVideoContainer