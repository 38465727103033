import React, {Component} from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom'
import './App.css';
import Wrapper from "./wrapper/Wrapper";
import Home from './containers/Home/Home'
import Services from './containers/Services/Services'
import Contact from './containers/Contact/Contact'

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {

        const { loading } = this.state

        let routes = (
            <Switch>
                <Route path="/services" component={Services} />
                <Route path="/contacts" component={Contact} />
                <Route path="/" component={Home}/>
                <Redirect to="/"/>
            </Switch>
        )


        if (loading) return (<p>Be Hold, fetching data may take some time :)</p>);
        else return (<Wrapper>{ routes }</Wrapper>)
    }
}

export default withRouter(App);
