import React from "react";
import classes from './StickyBtn.module.scss'
import { BiPaperPlane } from "react-icons/all"
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import BtnForm from "./BtnForm/BtnForm";
import ContactForm from "../ContactUs/ContactForm/ContactForm";


const StickyBtn = (props) => {
    const [toggled, setToggled] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <>
            <div className={classes.StickyBtn}>
               <div onClick={handleOpen}>
                    <BiPaperPlane className={classes.Icon}/>
               </div>
            </div>
            <Modal
                // aria-labelledby="transition-modal-title"
                // aria-describedby="transition-modal-description"
                className={classes.Modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.Paper}>
                        <h1>Contact Us</h1>
                        <ContactForm/>
                    </div>
                </Fade>
            </Modal>

        </>
    )};

export default StickyBtn