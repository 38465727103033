import React, {Component} from 'react'
import classes from './Contact.module.scss'
import BasicHeader from "../../components/BasicHeader/BasicHeader";
import Map from "../../components/ContactUs/Map/Map";
import GetInTouchForm from "../../components/ContactUs/GetInTouchForm/GetInTouchForm";
import IconBar from "../../components/ContactUs/IconBar/IconBar";

class Contact extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className={classes.Contact}>
                <BasicHeader headTitle={'Contact Us'}/>
                <IconBar/>
                <Map/>
                <GetInTouchForm/>
            </div>
        )
    }
}


export default Contact