import React from 'react'
import classes from './AboutUs.module.scss'
import Link from "react-router-dom/Link";
import LazyLoad from "react-lazyload";

const AboutUs = props => {
    return (
        <div className={classes.AboutUs}>
            <LazyLoad className={classes.Image} once offset={[100, 200]}>
                <img src={"home/about_us.jpg"} />
            </LazyLoad>
            <div
                className={classes.Description}
            >
                <h1>About Us</h1>
                <div className={classes.HeaderLine}/>
                <p className={classes.Paragraphs}>
                    Have you ever found yourself in an unfamiliar environment when you desperately required help
                    but couldn’t get any, and there was nobody to assist you? We have, too! And that’s exactly how
                    the idea of creating this project appeared. As we were studying at the university, we saw so many
                    foreign students dealing with daily situations, like getting a library card or finding an apartment,
                    and being unable to resolve them due to the language barrier, communication and cultural differences.
                    As we graduated and moved on to our lives abroad as students or employees, we faced the same type of
                    difficulties ourselves and not even once we hoped for someone to appear and magically solve all the issues for us.
                </p>

                <p className={classes.Paragraphs}>
                    And then it dawned upon us that we could become this magical someone for somebody else.
                    And that’s how Ukraine For Everyone was born. If you recognize yourself in the image above,
                    then we’re here for you. If you ever thought about moving to Ukraine to study, launch a business
                    or start your life from a fresh page, then we’re here to support you along this journey. We will
                    handle everything for you before you even arrive, including helping you find a place to study or
                    live, and will help you with all the paperwork. We provide a wide range of services, such as
                    university choice and full application cycle, documentation verification, handling your
                    accommodation issues, supporting your business launch, and real estate deals. We lead you every
                    step of the way until you reach the desired destination, supporting you on a daily basis in the meantime.
                </p>

                <h4 className={classes.Paragraphs}>
                    <Link
                        to={'/contacts'}
                    >
                        RECEIVE CONSULTATION
                    </Link>
                </h4>
            </div>
        </div>
    )
}

export default AboutUs