import React from "react";
import classes from './CollapsedServiceItem.module.scss'
import { CgAddR, CgCloseR } from "react-icons/all"


const Block = ({ isOpen, title, onToggle, children }) => {
    return (
        <div>
            <div className={classes.Block} onClick={onToggle}>
                <span
                    className={isOpen ? classes.active : ''}
                >{title}</span>
                {
                    isOpen ?
                        <CgCloseR className={classes.Icon}/>
                    :
                        <CgAddR className={classes.Icon}/>}
            </div>
            {
                isOpen ?
                    <div className={classes.Content}>
                        {children}
                    </div>
                :
                    <></>
            }
        </div>
    );
}

const CollapsedServiceItem = ({title, children}) => {
    const [toggled, setToggled] = React.useState(false);
    return (
        <div className={classes.CollapsedItem}>

            <Block
                title={title}
                isOpen={toggled}
                onToggle={() => setToggled(!toggled)}
            >
                    {children}
            </Block>

        </div>

)};

export default CollapsedServiceItem