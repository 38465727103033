import React from "react";
import ContactForm from "../ContactForm/ContactForm";


const GetInTouchForm = () => {

    return (
        <>
            <h1>Let's get in touch</h1>
            <ContactForm />
        </>
    )
}

export default GetInTouchForm