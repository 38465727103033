const nameValidation = (fieldValue) => {
  if (fieldValue.trim() === '') {
    return `Name is required`
  }
  if (/[^a-zA-Z -]/.test(fieldValue)) {
    return 'Invalid characters'
  }
  if (fieldValue.trim().length < 3) {
    return `Name needs to be at least three characters`
  }
  return null
}

const emailValidation = email => {
  if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email,
      )
  ) {
    return null
  }
  if (email.trim() === '') {
    return 'Email is required'
  }
  return 'Please enter a valid email'
}

const phoneValidation = phoneNumber => {
  if (!phoneNumber) {
    return 'Phone number is required'
  }

  if (
      /^[+]?[s./0-9]*[(]?[0-9]{1,4}[)]?[-s./0-9]*$/.test(phoneNumber)
  )
  return null
}

const messageValidation = message => {
  if(!message) {
    return 'At least 1 character is required!'
  }

  return null
}

const validate = {
  name: nameValidation,
  email: emailValidation,
  phone: phoneValidation,
  message: messageValidation
}

export default validate