import React from 'react'
import classes from './Reviews.module.scss'
import cx from 'classnames'

const ReviewWithVideoContainer = ({userName, userCountry, videoSrc=''}) => {
    return (
        <div className={cx(classes.ReviewContainer, classes.ReviewWithVideoContainer)}>
            <div className={classes.withVideoInfoContainer}>
                <span className={classes.UserName}>{userName}, </span>
                <span className={classes.UserCountry}>{userCountry}</span>
                <div className={classes.VideoContainer}>
                    <video controls>
                        <source src={videoSrc} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}

export default ReviewWithVideoContainer