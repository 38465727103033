import classes from "./Map.module.scss";
import React from "react";
import LazyLoad from "react-lazyload";


const Map = props => {
    return (
        <LazyLoad className={classes.Map} once offset={[100, 200]}>
            <img src={"home/contacts_map.png"} />
        </LazyLoad>
    )
}

export default Map