import React from "react";
import classes from './ContactForm.module.scss'
import validate from "../../../validator/validator";
import {useForm} from "react-hook-form";
import emailjs from "emailjs-com";
import ContactModal from "../../Modal/ContactModal";

const ContactForm = () => {
    const { register, handleSubmit, errors, formState  } = useForm({
        mode: "onBlur",
    });

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_sn2qzu3', 'template_2smyelb', e.target, 'user_E0i6OsFVyskpqXLbKISjS')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset()
    }

    const onSubmit = (data, e) => {
        sendEmail(e)
        console.log(data, e);
    }
    const onError = (errors, e) => console.log(errors, e);

    return (
        <div
            className={classes.ContactForm}
        >
            <form className={classes.InputForm} onSubmit={handleSubmit(onSubmit, onError)}>
                <ul className={classes.flexOuter}>
                    <li>
                        <label htmlFor={'name'}>Your name</label>
                        <input
                            name={'name'}
                            // ref={register({
                            //     required: true,
                            //     minLength: 6,
                            //     maxLength: 20,
                            //     pattern: /^[A-Za-z]+$/i,
                            // })}
                            ref={register({
                                required: true,
                                validate: (input) => validate.name(input)
                            })}
                            type={'text'}
                            id={'name'}
                            style={{ borderColor: errors.name && "red" }}
                            placeholder={''}
                        />
                    </li>
                    <li>
                        <label htmlFor={'phone'}>Your phone</label>
                        <input
                            name={'phone'}
                            ref={register({
                                required: true,
                                validate: (input) => validate.phone(input)
                            })}
                            type={'text'}
                            id={'phone'}
                            style={{ borderColor: errors.phone && "red" }}
                            placeholder={''}
                        />
                    </li>
                    <li>
                        <label htmlFor={'email'}>Your e-mail</label>
                        <input
                            name={'email'}
                            ref={register({
                                required: true,
                                validate: (input) => validate.email(input),
                            })}
                            type={'text'}
                            id={'email'}
                            style={{ borderColor: errors.email && "red" }}
                            placeholder={''}
                        />
                    </li>
                </ul>
                <div
                    className={classes.MessageForm}
                    placeholder={'Write your message'}
                >
                    <label htmlFor={'message'}>Your message</label>
                    <textarea
                        name={'message'}
                        ref={register({
                            required: true,
                            validate: (input) => validate.message(input),
                        })}
                        id={'message'}
                        style={{ borderColor: errors.email && "red" }}
                        placeholder={'Write your message here...'}
                    />
                </div>
                {formState.isSubmitted ?
                    <>
                        <p className={classes.ErrorMessage}>{errors.name?.message}</p>
                        <p className={classes.ErrorMessage}>{errors.phone?.message}</p>
                        <p className={classes.ErrorMessage}>{errors.email?.message}</p>
                        <p className={classes.ErrorMessage}>{errors.message?.message}</p>
                    </>
                    :''
                }
                <button
                    className={classes.ConfirmBtn}
                    // onClick={handleSubmit(onSubmit)}
                    disabled={formState.isSubmitting}
                    type="submit"
                >
                    <span>CONFIRM</span>
                </button>
            </form>
            {
                formState.isSubmitted && (Object.keys(errors).length === 0 && errors.constructor === Object) ?
                    <p className={classes.SuccessMessage}>Your request has been processed!</p>
                : ''
            }
            {/*maybe the button should be inside the form */}
            {/*<ContactModal/>*/}
            {/*<span>{errors}</span>*/}

        </div>
    )
}

export default ContactForm