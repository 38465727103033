import React from 'react'
import classes from './BottomNav.module.scss'
import NavLink from "react-router-dom/NavLink";

const BottomNav = props => {

    return (
        <div className={classes.BottomNav}>
            <NavLink to={'/'} className={classes.HomeNav}>
                UA 4 everyone
            </NavLink>
            <div className={classes.NavPages}>
                <NavLink to={'/'} className={classes.Links}>Home</NavLink>
                <NavLink to={'/services'} className={classes.Links}>Our Services and Prices</NavLink>
                <NavLink to={'/contacts'} className={classes.Links}>Contact Us</NavLink>
            </div>
            <span>All rights reserved 2021</span>

        </div>
    )
}

export default BottomNav