import React, {Component} from 'react'
import classes from './Services.module.scss'
import BasicHeader from "../../components/BasicHeader/BasicHeader";
import ServicesList from "../../components/Services/ServicesList/ServicesList";

class Services extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className={classes.Services}>
                <BasicHeader headTitle={'Our Services and Prices'}/>

                <ServicesList/>
            </div>
        )
    }
}


export default Services