import classes from "./IconBar.module.scss";
import {FaInstagram, ImFacebook, ImLocation2, ImPhone, IoIosMail} from "react-icons/all";
import React from "react";

const IconBar = (props) => {

    return (
        <>
        <div className={classes.IconBar}>
            <div className={classes.Block}>
                <ImLocation2 className={classes.IconItem}/>
                <span className={classes.firstLine}>Address:<br/></span>
                <span className={classes.secondLine}>Kharkiv, Ukraine</span>
            </div>

            <div className={classes.Block}>
                <ImPhone className={classes.IconItem} />
                <span className={classes.firstLine}>Phone:<br/></span>
                <a href={'tel:+3-068-874-1738'} style={{color: 'black'}}><span className={classes.secondLine}>+38 068 874 17 38</span></a>
            </div>

            <div className={classes.Block}>
                <ImFacebook className={classes.IconItem} />
                <span className={classes.firstLine}>Follow us:<br/></span>
                <a href={'https://www.facebook.com/Ukraine-For-Everyone-108731837620625'}>
                    <span className={classes.secondLine}>Facebook</span>
                </a>
            </div>

            <div className={classes.Block}>
                <FaInstagram className={classes.IconItem} />
                <span className={classes.firstLine}>Follow us:<br/></span>
                <a href={'https://www.instagram.com/ukraine4everyone/'}>
                    <span className={classes.secondLine}>Instagram</span>
                </a>
            </div>

            <div className={classes.Block}>
                <IoIosMail className={classes.IconItem}/>
                <span className={classes.firstLine}>Mail:<br/></span>
                <span className={classes.secondLine}><a href={'mailto:ukraine4everyone@gmail.com?subject=Service Request'}>info@ukraine4everyone.com</a></span>
            </div>
        </div>

        {/*<div className={classes.IconBar}>*/}
        {/*    <span>*/}
        {/*        Severoynamennaya street 42a*/}
        {/*    </span>*/}
        {/*</div>*/}
    </>
    )
}


export default IconBar