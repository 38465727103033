import React from 'react'
import classes from './Reviews.module.scss'
import LazyLoad from 'react-lazyload'

const ReviewContainer = ({userName, userCountry, userFeedback, imgSrc, videoSrc=''}) => {
    console.log(userName, userCountry, imgSrc)
    return (
       <div className={classes.ReviewContainer}>
           <LazyLoad className={classes.MediaContainer} once offset={[100, 200]}>
               <img src={imgSrc} alt={`${userName} picture`} />
           </LazyLoad>
           <div className={classes.FeedbackContainer}>
               <span className={classes.UserName}>{userName}, </span>
               <span className={classes.UserCountry}>{userCountry}</span>
               <p className={classes.UserFeedback}>{userFeedback}</p>
           </div>
       </div>
    )
}

export default ReviewContainer