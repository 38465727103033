import React from "react";
import { slide as Menu } from 'react-burger-menu'
import './Sidebar.css'
import {Link} from "react-router-dom";
import cx from 'classnames'


class Sidebar extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }

    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    closeMenu () {
        this.setState({menuOpen: false})
    }

    toggleMenu () {
        this.setState(state => ({menuOpen: !state.menuOpen}))
    }

    render () {
        console.log(window.location.pathname)
        return (
            <div>
                <Menu
                    isOpen={this.state.menuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                    disableAutoFocus
                >
                    <Link
                        className={
                            cx("menu-item",
                                !isActive('services') &&
                                !isActive('contacts') ? 'active-item' : ''
                            )
                        }
                        to="/" onClick={() => this.closeMenu()}>
                        Home
                    </Link>

                    <Link
                        className={cx("menu-item", isActive('services') ? 'active-item' : '')}
                        to="/services"
                        onClick={() => this.closeMenu()}
                    >
                        Our Services and Prices
                    </Link>

                    <Link
                        className={cx("menu-item", isActive('contacts') ? 'active-item' : '')}
                        to="/contacts"
                        onClick={() => this.closeMenu()}
                    >
                        Contact Us
                    </Link>
                </Menu>
            </div>
        )
    }
}

const isActive = (pathname) => {
    return window.location.pathname.includes(pathname)
}

export default Sidebar