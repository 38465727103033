import React from 'react'
import classes from './WhyChooseUs.module.scss'
import LazyLoad from "react-lazyload";

const WhyChooseUs = props => {
    return (
        <div className={classes.WhyChooseUs}>
                <div className={classes.Description}>
                    <h1>Why choose us</h1>
                    <div className={classes.HeaderLine} />
                    <div className={classes.Blocks}>
                        <div className={classes.RowBlock}>
                            <div className={classes.RowBlockColumn}>
                                <h1 className={classes.NumberedBlocks}>01</h1>
                                <h3>100% guarantee of the result</h3>
                            </div>
                            <div className={classes.RowBlockColumn}>
                                <h1 className={classes.NumberedBlocks}>02</h1>
                                <h3>Individual approach to every client</h3>
                            </div>
                        </div>
                        <div className={classes.RowBlock}>
                            <div className={classes.RowBlockColumn}>
                                <h1 className={classes.NumberedBlocks}>03</h1>
                                <h3>We speak your language</h3>
                            </div>
                            <div className={classes.RowBlockColumn}>
                                <h1 className={classes.NumberedBlocks}>04</h1>
                                <h3>24/7 support during our cooperation</h3>
                            </div>
                        </div>
                        <div className={classes.RowBlock}>
                            <div className={classes.RowBlockColumn}>
                                <h1 className={classes.NumberedBlocks}>05</h1>
                                <h3>Programs tailor-made for you personally</h3>
                            </div>
                            <div className={classes.RowBlockColumn}>
                                <h1 className={classes.NumberedBlocks}>06</h1>
                                <h3>Your first trustworthy friend in Ukraine</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <LazyLoad className={classes.Image} once offset={[100, 200]}>
                    <img src={"home/why_choose_us.jpg"} />
                </LazyLoad>
        </div>
    )
}

export default WhyChooseUs