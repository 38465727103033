import React from 'react'
import classes from './Header.module.scss'
import NavLink from "react-router-dom/NavLink";

const Header = props => {
    return (
        <div className={classes.Header}>
            {/*<HeaderNav/>*/}
            <h1>UKRAINE FOR EVERYONE</h1>
            <h2>Any help in Ukraine you may need</h2>
            <NavLink
                className={classes.Link}
                to={'/contacts'}
            >
                <h4>RECEIVE CONSULTATION</h4>
            </NavLink>

        </div>
    )
}

export default Header