import React from 'react'
import classes from './ServicesList.module.scss'
import CollapsedServiceItem from "../CollapsedServiceItem/CollapsedServiceItem";

const ServicesList = props => {
    return (
        <div className={classes.ServicesList}>
            <CollapsedServiceItem
                title={'Rental housing'}
            >
                    <ul>
                            <li>Apartment/house selection;</li>
                            <li>Onsite property visits with an interpreter;</li>
                            <li>Online preview before your arrival (via video calls/photos);</li>
                            <li>Signing the rental contract</li>
                    </ul>
                    <span>Service Price: 10% from the price of the first month’s rent</span>
            </CollapsedServiceItem>

            <CollapsedServiceItem
                title={'Cleaning service and domestic issues support'}
            >
                <ul>
                        <li>Cleaning and ironing service (3 times \ month);</li>
                        <li>Fixing your basic domestic issues, such as finding a plumber, minor renovations, equipment installations, Internet connection etc.</li>
                </ul>
                <span>Service Price: from 95$ (depending on square meters)</span>
            </CollapsedServiceItem>

            <CollapsedServiceItem
                title={'Housing and vehicle purchase'}
            >
                    <ul>
                            <li>Apartment/house/vehicle selection;</li>
                            <li>Onsite property visits with interpreter;</li>
                            <li>Online preview before your arrival (via video calls/photos);</li>
                            <li>Preparation of all the paperwork up to closing the deal;</li>
                            <li>Assistance in managing the property (learning how to pay the bills, Internet and other services connectivity, technical inspection of the vehicle etc.).</li>
                    </ul>
                    <span>Service Price: 1% from the deal price</span>
            </CollapsedServiceItem>

            <CollapsedServiceItem
                title={'Banking services'}
            >
                    <ul>
                            <li>Comparative chart of 5 different banks according to your needs;</li>
                            <li>Assistance in bank selection;</li>
                            <li>Visiting the selected bank to open an account (with an interpreter);</li>
                            <li>Money transfers consultation.</li>
                    </ul>
                    <span>Service Price: from 100$ depending on requests</span>
            </CollapsedServiceItem>

            <CollapsedServiceItem
                title={'Health insurance'}
            >
                    <ul>
                            <li>Assistance in choosing a health insurance company/clinics;</li>
                            <li>Visiting the selected clinic for registration and primary consultation with the doctor (with an interpreter);</li>
                            <li>Medical certificates to whom it may concern, X-rays, etc.</li>
                    </ul>
                    <span>Service Price: from 100$ depending on requests</span>
            </CollapsedServiceItem>

            <CollapsedServiceItem
                title={'University selection'}
            >
                    <ul>
                            <li>Comparative chart of up to 5 different universities according to your requirements;</li>
                            <li>Providing you with all the information about the required documents and terms of application to the selected university;</li>
                            <li>Assistance in preparing all the necessary documents and support up to the university enrollment;</li>
                            <li>Basic help with the organization of your first arrival to Ukraine (tickets selection, meeting at
                                    the airport, transfer to your accommodation, first visit to the university with an interpreter).</li>
                    </ul>
                    <span>Service Price: 1200$</span>
            </CollapsedServiceItem>

            <CollapsedServiceItem
                title={'Business startup in Ukraine'}
            >
                    <ul>
                            <li>Company registration according to Ukrainian legislation;</li>
                            <li>Paperwork preparation required for your legal stay in Ukraine;</li>
                            <li>Extra services on demand.</li>
                    </ul>
                    <span>Service Price: from 1500$</span>
            </CollapsedServiceItem>

            <CollapsedServiceItem
                title={'Other services'}
            >
                    <ul>
                            <li>Assistance in purchasing furniture and domestic goods;</li>
                            <li>Assistance in import and export of your pet;</li>
                            <li>Language courses;</li>
                            <li>Any other individual service upon your request.</li>
                    </ul>
                    <span></span>
            </CollapsedServiceItem>

        </div>
    )
}


export default ServicesList