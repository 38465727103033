import React, {useEffect, useState} from 'react'
import classes from './HeaderNav.module.scss'
import { ImPhone, IoIosMail, ImFacebook, FaInstagram} from "react-icons/all";
import NavLink from "react-router-dom/NavLink";


const HeaderNav = props => {
    
    const [activeNav, setActiveNav] = useState('Home')

    useEffect(() => {
        if(window.location.href.includes('services')) {
            setActiveNav('Services')
        } else if(window.location.href.includes('contacts')) {
            setActiveNav('Contacts')
        } else {
            setActiveNav('Home')
        }
    })

    const handleScroll = () => {

    }


    return (
        <div
            className={classes.HeaderNav}
            onScroll={handleScroll}
        >
            <div className={classes.NavContainer}>
            <div className={classes.Logo} />

            <div className={classes.NavPages}>
                <NavLink
                    className={activeNav === 'Home' ? classes.activeNav : null}
                    to={'/'}
                >
                    Home
                </NavLink>

                <NavLink
                    className={activeNav === 'Services' ? classes.activeNav : null}
                    to={'/services'}
                >
                    Our Services and Prices
                </NavLink>

                <NavLink
                    className={activeNav === 'Contacts' ? classes.activeNav : null}
                    to={'/contacts'}
                >
                    Contact Us
                </NavLink>
            </div>

            <div className={classes.IconBar}>
                <a href={'tel:+3-068-874-1738'}><ImPhone className={classes.IconItem}/>&nbsp;</a>
                <a href={'tel:+3-068-874-1738'}><span>+38 068 874 17 38</span></a>
                <span className={classes.DisabledIcon}>|</span>&nbsp;
                <a href={'mailto:ukraine4everyone@gmail.com?subject=Service Request'}><IoIosMail className={classes.IconItem}/></a>&nbsp;
                <a href={'https://www.facebook.com/Ukraine-For-Everyone-108731837620625'}><ImFacebook className={classes.IconItem}/></a>&nbsp;
                <a href={'https://www.instagram.com/ukraine4everyone/'}><FaInstagram className={classes.IconItem}/></a>&nbsp;
                <span className={classes.DisabledIcon}>|</span>
                {/*<span className={classes.IconItem}>EN</span>*/}
            </div>

            </div>
        </div>
    )
}

export default HeaderNav