import React, {Component} from 'react'
import classes from './Home.module.css'
import Header from "../../components/Home/Header/Header";
import AboutUs from "../../components/Home/AboutUs/AboutUs";
import WhyChooseUs from "../../components/Home/WhyChooseUs/WhyChooseUs";
import EBRENav from "../../components/Home/EBRENav/EBRENav";
import Reviews from "../../components/Home/Reviews/Reviews";
import News from "../../components/Home/News/News";
import Contacts from "../../components/Home/Contacts/Contacts";

class Home extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className={classes.Home}>
                <Header/>
                <AboutUs/>
                <WhyChooseUs/>
                <EBRENav/>
                <Reviews/>
                <News/>
                <Contacts/>
            </div>
        )
    }
}


export default Home