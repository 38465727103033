import React from 'react'
import classes from './News.module.scss'
import { IoMdClose} from "react-icons/all";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import LazyLoad from "react-lazyload";

const News = props => {

    const [openCovid, setOpenCovid] = React.useState(false);
    const [openKharkiv, setOpenKharkiv] = React.useState(false);
    const [openUkraine, setOpenUkraine] = React.useState(false);

    const openCovidNewsModal = () => setOpenCovid(true)
    const openUkraineNewsModal = () => setOpenUkraine(true)
    const openKharkivNewsModal = () => setOpenKharkiv(true)
    const closeCovidNewsModal = () => setOpenCovid(false)
    const closeKharkivNewsModal = () => setOpenKharkiv(false)
    const closeUkraineNewsModal = () => setOpenUkraine(false)

    return (
        <div className={classes.News}>
            <div className={classes.NewsContainer}>
                <h1>Our News</h1>
                <div className={classes.HeaderLine} />

                <div className={classes.GridContainer}>
                    <div className={classes.GridItem}>
                        <Paper className={classes.Paper} onClick={openCovidNewsModal}>
                            <LazyLoad className={classes.NewImage} once offset={[100, 200]}>
                                <img id="covid" src={"home/news/Covid-19.jpg"} />
                            </LazyLoad>
                            <div className={classes.SmallWrapper}>
                                <div className={classes.Line}/>
                                {/*<span>17 JULY 2020</span>*/}
                                <h2>Covid-19</h2>
                            </div>
                        </Paper>
                        <Modal
                            // aria-labelledby="transition-modal-title"
                            // aria-describedby="transition-modal-description"
                            className={classes.Modal}
                            open={openCovid}
                            onClose={closeCovidNewsModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={openCovid}>
                                <div className={classes.NewsModalContainer}>
                                    <IoMdClose className={classes.CloseIcon} onClick={closeCovidNewsModal}/>
                                    <h1>Covid-19</h1>
                                    <div>
                                        <p>
                                            Covid-19 brought changes to our lifestyle but it's not the reason to spend weeks in self-isolation 😉
                                            Now if you're from the "red zone" country, entering Ukraine you have an opportunity to pass the test for
                                            coronavirus right in the Kharkiv airport or any clinic in the city. The advantages of passing the test are obvious:
                                        </p>
                                        <ul>
                                            <li>⚡ fast result,</li>
                                            <li>🏻‍♀️ no need to stay 2 weeks in self-isolation,</li>
                                            <li>🏙️ you can start exploring Kharkiv immediately,</li>
                                            <li>💪🏼 chance to check your health and make sure you're ready for active life.</li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                    <div className={classes.GridItem}>
                        <Paper className={classes.Paper} onClick={openKharkivNewsModal}>
                            <LazyLoad className={classes.NewImage} once offset={[100, 200]}>
                                <img id="kharkiv" src={"home/news/Kharkiv.jpg"} />
                            </LazyLoad>
                            <div className={classes.SmallWrapper}>
                                <div className={classes.Line}/>
                                {/*<span>17 JULY 2020</span>*/}
                                <h2>Why Kharkiv is the best city in Ukraine?</h2>
                            </div>
                        </Paper>
                        <Modal
                            // aria-labelledby="transition-modal-title"
                            // aria-describedby="transition-modal-description"
                            className={classes.Modal}
                            open={openKharkiv}
                            onClose={closeKharkivNewsModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={openKharkiv}>
                                <div className={classes.NewsModalContainer}>
                                    <IoMdClose className={classes.CloseIcon} onClick={closeKharkivNewsModal} />
                                    <h1>Why Kharkiv is the best city in Ukraine? 🤔</h1>
                                    <div>
                                        <p>
                                            Kharkiv is the second largest city in Ukraine, known as the city of business, science and universities.
                                            Among all its advantages we would highlight:
                                        </p>
                                        <ul>
                                            <li>good prices for real estate (and there are many options from antique buildings in the center to the brand-new townhouses in elite green and quiet neighborhoods) 🏡🏙️</li>
                                            <li>plenty of parks and forests (Kharkiv is one of the greenest cities in all Ukraine!) 🌳🌄</li>
                                            <li>youth city (Kharkiv is famous for its universities and there are students from all around the world 🌍🌎🌏</li>
                                            <li>affordable life (despite being the second largest city in Ukraine, Kharkiv still offers affordable prices for food, apartments, education and entertainment) 💸</li>
                                            <li>wide range of transport connections both inside and outside the city (there's a wide metro and bus system in the city, a railway station, bus terminals and an international airport with flights all over the world) 🚄🚌✈️</li>
                                            <li>a lot of restaurants, cafés, bars and night clubs for every taste 🍝🥐🍹</li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                    <div className={classes.GridItem}>
                        <Paper className={classes.Paper} onClick={openUkraineNewsModal}>
                            <LazyLoad className={classes.NewImage} once offset={[100, 200]}>
                                <img id="ukraine" src={"home/news/Ukraine.jpg"} />
                            </LazyLoad>
                            <div className={classes.SmallWrapper}>
                                <div className={classes.Line}/>
                                {/*<span>17 JULY 2020</span>*/}
                                <h2>Top stereotypes about Ukraine</h2>
                            </div>
                        </Paper>
                        <Modal
                            // aria-labelledby="transition-modal-title"
                            // aria-describedby="transition-modal-description"
                            className={classes.Modal}
                            open={openUkraine}
                            onClose={closeUkraineNewsModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={openUkraine}>
                                <div className={classes.NewsModalContainer}>
                                    <IoMdClose className={classes.CloseIcon} onClick={closeUkraineNewsModal} />
                                    <h1>Top stereotypes about Ukraine</h1>
                                    <div>
                                        <ul>
                                            <li style={{fontWeight: 600}}>All Ukrainians love borscht, salo and vodka 🍲</li>
                                            Borscht (the beetroot soup), salo (pork lard) and vodka are for sure an important part of our national cuisine, though the latter is much more diverse and has plenty of dishes and tastes to surprise you.
                                            <br/>
                                            <li style={{fontWeight: 600}}>Everything in Ukraine is cheap 💰</li>
                                            It was some years ago when we faced a huge economic crisis and for the majority of the tourists it was very cheap to travel, study and live here. But the situation is changing and the country is developing fast. Still being an affordable country, Ukraine attracts many foreigners with competitive prices and high level of service in every sphere.
                                            <br/>
                                            <li style={{fontWeight: 600}}>Ukrainian women are the most beautiful 💃</li>
                                            Wherever you go in Ukraine, while walking around in the streets of the city you can notice the beauty of our women. It's one of our national treasures and we can't deny it 😌
                                            <br/>
                                            <li style={{fontWeight: 600}}>Ukraine is a low-developed country 🏡</li>
                                            Many people still think about Ukraine as a part of the Soviet Union. However, the last 28 years of our independence were an incredible way of a complete transformation. You'll be pleasantly surprised with our beautiful and clean cities, plenty of green parks and developed infrastructure.
                                            It's always better to see everything with own eyes, so we're waiting for you in Ukraine 🇺🇦
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News