import React from 'react'
import classes from './Contacts.module.scss'
import {FaInstagram, ImFacebook, ImPhone, IoIosMail, ImLocation2} from "react-icons/all";
import LazyLoad from "react-lazyload";

const Contacts = props => {

    return (
        <div className={classes.Contacts}>
            <div className={classes.ContactsWrapper}>
                <LazyLoad className={classes.Map} once offset={[100, 200]}>
                    <img src={"home/contacts_map.png"} />
                </LazyLoad>
                <div className={classes.Description}>
                    <h1>
                        Contacts
                    </h1>
                    <div className={classes.IconBar}>
                        <div className={classes.LeftSide}>
                            <a href={'tel:+3-068-874-1738'}>
                                <ImPhone className={classes.IconItem}/>
                                <span>+38 068 874 17 38</span>
                            </a>
                            <div className={classes.AddressIconBar}>
                                <ImLocation2 className={'AddressIcon'}/>
                                <span>
                                    Address:<br/>
                                </span>
                                <span>Kharkiv, Ukraine</span>
                            </div>
                        </div>
                        <div className={classes.RightSide}>
                            <a href={'mailto:ukraine4everyone@gmail.com?subject=Service Request'}>
                                <IoIosMail className={classes.IconItem}/>
                            </a>
                            <a href={'https://www.facebook.com/Ukraine-For-Everyone-108731837620625'}><ImFacebook
                                className={classes.IconItem}
                            /></a>
                            <a href={'https://www.instagram.com/ukraine4everyone/'}><FaInstagram
                                className={classes.IconItem}
                            /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts