import React from 'react'
import classes from './Reviews.module.scss'
import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel';
import { IoIosArrowBack, IoIosArrowForward} from "react-icons/all";
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReviewContainer from "./ReviewContainer";
import {OguzFeedback, YolandaFeedback, EugeneFeedback} from "./feedback_helper";
import ReviewWithVideoContainer from "./ReviewWithVideoContainer";
import ReviewWithVerticalVideoContainer from "./ReviewWithVerticalVideoContainer";

const Reviews = props => {

    return (
        <CarouselProvider
            naturalSlideWidth={100}
            // naturalSlideHeight={40}
            totalSlides={4}
            infinite={true}
            touchEnabled={true}
            isPlaying={false}
            className={classes.Reviews}
        >
            <h1>Check out our customer reviews</h1>

            <Slider>
                <Slide index={0} className={classes.SlideContainer}>
                    <ReviewContainer
                        userName={'Oguz'}
                        userCountry={"Turkey"}
                        userFeedback={OguzFeedback}
                        imgSrc={'reviews/oguz_review.png'}
                    />
                </Slide>
                <Slide index={1} className={classes.SlideContainer}>
                    <ReviewContainer
                        userName={"Yolanda Arriaga"}
                        userCountry={'USA'}
                        userFeedback={YolandaFeedback}
                        imgSrc={'reviews/yolanda_review.jpg'}
                    />
                </Slide>
                <Slide index={2} className={classes.SlideContainer}>
                    <ReviewWithVideoContainer
                    userName={"Aníbal"}
                    userCountry={'Chile'}
                    videoSrc={'video_reviews/anibal_chile.mp4'}
                    />
                    <ReviewWithVerticalVideoContainer
                        userName={"Joao"}
                        userCountry={'Portugal'}
                        videoSrc={'video_reviews/joao.mp4'}
                    />
                </Slide>
                <Slide index={3} className={classes.SlideContainer}>
                    <ReviewContainer
                        userName={"Eugene"}
                        userCountry={"Romania"}
                        userFeedback={EugeneFeedback}
                        imgSrc={'reviews/eugene_review.jpg'}
                    />
                </Slide>
            </Slider>
            {/*<DotGroup showAsSelectedForCurrentSlideOnly={true}/>*/}
            <ButtonBack className={classes.BackButton}>
                <IoIosArrowBack className={classes.Arrow}/>
            </ButtonBack>
            <ButtonNext className={classes.NextButton}>
                <IoIosArrowForward className={classes.Arrow}/>
            </ButtonNext>
        </CarouselProvider>
    )
}

export default Reviews