import React, {Component} from 'react'
import classes from './Wrapper.module.css'
import HeaderNav from "../components/Navigation/HeaderNav/HeaderNav";
import BottomNav from "../components/Navigation/BottomNav/BottomNav";
import StickyBtn from "../components/StickyBtn/StickyBtn";
import Sidebar from "../components/Navigation/Sidebar/Sidebar";

class Wrapper extends Component {

  render() {
    return (
      <div className={classes.Wrapper} id="outer-container">
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <div id="page-wrap">
              <HeaderNav/>
              <main>
                  { this.props.children }
              </main>
              <StickyBtn/>
              <BottomNav/>
          </div>
      </div>
    )
  }
}

export default Wrapper